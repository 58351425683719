<template>
  <div class="row" v-if="checkPer('teachers|stats')">
    <div class="col-12 col-lg-3 g">
      <div class="card" style="border-radius: 10px !important">
        <div class="card-hedaer g">
          <h5>
            <i class="fa fa-check-square"></i>
            عدد الحاضرين
          </h5>
        </div>
        <div class="card-body bg-success" style="border-radius: 0px 0px 10px 10px !important">
          <span style="font-size: 20px" class="text-white">
            {{ reports[1] || reports[2] ? (reports[1] ? reports[1] : 0) + (reports[2] ? reports[2] : 0) : 0 }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3 g">
      <div class="card" style="border-radius: 10px !important">
        <div class="card-hedaer g">
          <h5>
            <i class="fa fa-clock-o"></i>
            عدد المتأخرين
          </h5>
        </div>
        <div class="card-body bg-warning" style="border-radius: 0px 0px 10px 10px !important">
          <span style="font-size: 20px" class="text-white">
            {{ reports[2] ? reports[2] : 0 }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3 g">
      <div class="card" style="border-radius: 10px !important">
        <div class="card-hedaer g">
          <h5>
            <i class="fa fa-user-times"></i>
            عدد الغياب
          </h5>
        </div>
        <div class="card-body" style="border-radius: 0px 0px 10px 10px !important; border-top: 1px solid #333">
          <div class="row">
            <div class="col-6 text-center">
              <span style="font-size: 20px" class="text-danger">
              {{ reports[3] ? reports[3] : 0 }} بدون عذر
            </span>
            </div>
            <div class="col-6 text-center">
              <span style="font-size: 20px" class="text-primary">
              {{ reports[5] ? reports[5] : 0 }} بعذر 
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3 g">
      <div class="card" style="border-radius: 10px !important">
        <div class="card-hedaer g">
          <h5>
            <i class="fa fa-sign-out"></i>
            عدد الانصرافات
          </h5>
        </div>
        <div class="card-body bg-secondary" style="border-radius: 0px 0px 10px 10px !important">
          <span style="font-size: 20px" class="text-white">
            {{ leaves }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4" style="z-index: 9">
      <div id="chartContainer"></div>
    </div>
    <div class="col-12 col-lg-8">
        <div class="d-block d-lg-none">
            <br><br><br><br>
            <br><br><br><br>
            <br><br><br><br>
            <br><br><br><br>
            <br><br><br><br>
            <br>
        </div>
        <div class="card card-body">
          <div class="col-12 text-center g">
            <button class="btn btn-primary btn-sm" style="border-radius: 0px" @click="print()">
              <i class="fa fa-print"></i>
              طباعة سجل العمل الرسمي للمعلمين
            </button>
            &nbsp;
            <input type="date" v-model="date" @change="getReport()">
          </div>
            <div class="col-12 table-responsive" style="min-height: auto">
                <table class="table table-hover table-bordered table-sm table-striped">
                    <thead>
                        <th>
                            المعلم
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            التوقيت
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="report in list" :key="report.number">
                            <td>
                              {{ report.name }}
                            </td>
                            <td>
                              <template v-if="report.status">
                                  <span class="badge badge-secondary" v-if="report.status == 0"
                                  >خارج وقت الدوام</span
                                  >
                                  <span class="badge badge-success" v-if="report.status == 1"
                                  >حاضر</span
                                  >
                                  <span class="badge badge-warning lated" v-if="report.status == 2"
                                  >متأخر</span
                                  >
                                  <span class="badge badge-danger" v-if="report.status == 3"
                                  >غائب</span
                                  >
                                  <span class="badge badge-info" v-if="report.status == 6"
                                  >اجازة</span
                                  >
                                  <span class="badge badge-primary" v-if="report.status == 5"
                                  >غائب بعذر</span
                                  >
                              </template>
                            </td>
                            <td>
                              <span>{{ report.join.time }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
const axios = require("axios");
import CanvasJS from '@canvasjs/charts';

var moment = require("moment-hijri");
export default {
    components: {
        VueApexCharts,
    },
    data(){
        return {
            reports: {},
            list: [],
            leaves: 0,
            shifts: {},
            days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday"],
            user: JSON.parse(localStorage.getItem('user')),
            loaded: false,
            date: new Date().toISOString().split("T")[0]
        }
    },
    created(){
        var g = this;
            axios
        .post(api + "/user/teachers/shifts", {
            jwt: g.user.jwt
        })
        .then(function (r) {
          if(r.data.response){
            g.shifts = JSON.parse(r.data.response);
          }
        })
        var x = setInterval(() => {
            if(g.loaded){
              clearInterval(x)
              var chart = new CanvasJS.Chart("chartContainer", {
              theme: "light2",
              animationEnabled: true,
              title:{
                  text: "احصائيات الغياب"
              },
              styles: {
              width: "100%",
              height: "360px"
              },
              data: [{
                  type: "pie",
                  indexLabel: "{label} (#percent%)",
                  yValueFormatString: "#,##0",
                  toolTipContent: "<span style='\"'color: {color};'\"'>{label}</span> {y} (#percent%)",
                  dataPoints: [
                  { label: "الحضور", y: g.reports[1] },
                  { label: "التأخر", y: g.reports[2] },
                  { label: "الغياب", y: g.reports[3] },
                  { label: "الانصراف", y: g.reports[4] },
                  { label: "غياب بعذر", y: g.reports[4] },
                  ].map(function(a){
                  if(a.y > 0){
                      return a;
                  }else{
                      return false;
                  }
                  }).filter((x)=>{return x;})
              }]
              });
              chart.render();
            }
        }, 100);
        g.getReport()
    },
    methods: {
      print(){
        var g = this;
        var win = window.open("");
        moment.locale("ar-SA");
        var days = [
          "اﻷحد",
          "اﻷثنين",
          "الثلاثاء",
          "اﻷربعاء",
          "الخميس",
          "الجمعة",
          "السبت",
        ];
        var d = new Date(g.date);
        var dayname = days[d.getDay()];
        var date2 =
          moment(d.toISOString().split("T")[0], "YYYY-M-D").format("iYYYY") + "هـ";
        var date =
          moment(d.toISOString().split("T")[0], "YYYY-M-D").format("iYYYY/iM/iD") + "هـ";

          var attend = "", ii = 0, count = 0, attend2;

          g.list.forEach(function(a, i){
            if((a.status == 1 || a.status == '0')){
              ii++;
              count++;
              if( ii < 51){
                attend = attend + `
                <tr>
                  <td>${ii}</td>
                  <td>${a.name}</td>
                  <td>&nbsp;&nbsp;&nbsp;</td>
                  <td>${a?.join?.time?.split(" ")[1] ?? '----'}</td>
                  <td>${a?.leave?.time?.split(" ")[1] ?? '----'}</td>
                </tr>
                `
              }
              if( ii >= 51){
                attend2 = attend2 + `
                <tr>
                  <td>${ii}</td>
                  <td>${a.name}</td>
                  <td>&nbsp;&nbsp;&nbsp;</td>
                  <td>${a?.join?.time?.split(" ")[1] ?? '----'}</td>
                  <td>${a?.leave?.time?.split(" ")[1] ?? '----'}</td>
                </tr>
                `
              }
            }
          })
          

          var late = "", ii = 0;

          g.list.forEach(function(a, i){
            if(a.status == 2){
              ii++;
              late = late + `
              <tr>
                <td>${ii}</td>
                <td>${a.name}</td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td>${a?.join?.time?.split(" ")[1] ?? '----'}</td>
                <td>${a?.leave?.time?.split(" ")[1] ?? '----'}</td>
              </tr>
              `
            }
          })
          var ghiab = "", ii = 0;

          g.list.forEach(function(a, i){
            if(a.status == 3){
              ii++;
              ghiab = ghiab + `
              <tr>
                <td>${ii}</td>
                <td>${a.name}</td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td>غير مبرر</td>
              </tr>
              `
            }
          })
          g.list.forEach(function(a, i){
            if(a.status == 5){
              ii++;
              ghiab = ghiab + `
              <tr>
                <td>${ii}</td>
                <td>${a.name}</td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td>${a.reason}</td>
              </tr>
              `
            }
          })
          var shifts = "";
          if(g.shifts){
            if(g.shifts[g.days[d.getDay()]] || g?.shifts[g.days[d.getDay()]]?.length == 0){
              g.shifts[g.days[d.getDay()]].forEach(function(a){
                shifts = shifts + `
                <tr>
                  <td>${a}</td>
                  <td>&nbsp;&nbsp;&nbsp;</td>
                </tr>
                `
              })
            }else{
              shifts = shifts + `
                <tr>
                  <td>&nbsp;&nbsp;&nbsp;</td>
                  <td>&nbsp;&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;&nbsp;&nbsp;</td>
                  <td>&nbsp;&nbsp;</td>
                </tr>
                `
            }
          }else{
              shifts = shifts + `
                <tr>
                  <td>&nbsp;&nbsp;&nbsp;</td>
                  <td>&nbsp;&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;&nbsp;&nbsp;</td>
                  <td>&nbsp;&nbsp;</td>
                </tr>
                `
            }
            var two = "";
            if(count>=51){
              var two = `<div class='container-fluid'>
              <div class='row'>
              <div class='col-12 header'>
                <div class='row'>
                <div class='col-4 text-center'>
                المملكة العربية السعودية<br>
                وزارة التعليم
                <br>
                ${g.user.name}
              </div>
              <div class='col-4 text-center'>
                <img src='https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg' style='width: 150px'>
              </div>
              <div class='col-4'>

                اسم النموذج: سجل العمل الرسمي
                <br>
                سجل دوام الموظفين للعام الدراسي: ${date2}
                <br>
                يوم: ${dayname} الموافق ${date}
              </div>
              </div>
              </div>
              <div class='col-6'>
                <table class='table table-striped table-sm'>
                  <thead style='background: #C0EBA6'>
                  <tr><th colspan="6" class='text-center' style='background: #A2CA71'>سجل الحاضرين قبل الاصطفاف الصباحي</th></tr>
                    <tr><th>
                      م
                    </th>
                    <th>
                      الاسم
                    </th>
                    <th>
                      الوظيفة
                    </th>
                    <th>
                      وقت الحضور
                    </th>
                    <th>
                      وقت الخروج
                    </th></tr>
                  </thead>
                  <tbody>${attend2}</tbody>
                </table>
                
              </div>
              <div class='col-6'>

                <table class='table table-striped table-sm'>
                  <thead style='background: #C0EBA6'>
                  <tr><th colspan="6" class='text-center' style='background: #A2CA71'>المعلمون المتأخرون</th></tr>
                    <tr><th>
                      م
                    </th>
                    <th>
                      الاسم
                    </th>
                    <th>
                      الوظيفة
                    </th>
                    <th>
                      وقت الحضور
                    </th>
                    <th>
                      وقت الخروج
                    </th></tr>
                  </thead>
                  <tbody></tbody>
                </table>
                <table class='table table-striped table-sm'>
                  <thead style='background: #C0EBA6'>
                    <tr><th colspan="6" class='text-center' style='background: #A2CA71'>الموظفون الغائبون</th></tr>
                    <tr><th>
                      م
                    </th>
                    <th>
                      الاسم
                    </th>
                    <th>
                      الوظيفة
                    </th>
                    <th>
                      سبب الغياب
                    </th></tr>
                  </thead>
                  <tbody></tbody>
                </table>
                <table class='table table-striped table-sm'>
                  <thead style='background: #C0EBA6'>
                    <tr><th colspan="6" class='text-center' style='background: #A2CA71'>المعلم المناوب بداية ونهاية العمل</th></tr>
                    <tr><th>
                      اسم المعلم
                    </th><th>التوقيع</th></tr>
                  </thead>
                  <tbody><tr>
                  <td>&nbsp;&nbsp;&nbsp;</td>
                  <td>&nbsp;&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;&nbsp;&nbsp;</td>
                  <td>&nbsp;&nbsp;</td>
                </tr></tbody>
                </table>
                <table class='table table-striped table-sm'>
                  <thead style='background: #C0EBA6'>
                    <tr><th colspan="6" class='text-center' style='background: #A2CA71'>المشرف الزائر</th></tr>
                    <tr><th>
                      اسم المشرف
                    </th>
                    <th>
                      تخصصه
                    </th>
                    <th>
                      توقيعه
                    </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <span style='position: fixed; bottom: 0px;'>ملاحظة: يغلق الدوام مرتين في الفترة الأولى بعد بداية البرنامج الصباحي مباشرة وفي المرة الثانية عند بداية الحصة الأولى مباشرة</span>
            </div>
            </div>`.replace("undefined", "");
              
            }
        win.document.write(`
        <html dir='rtl'>
          <head><link href="https://fonts.cdnfonts.com/css/arial-2" rel="stylesheet">

            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css">
            <style>
              table, td, th {
                border: 1px solid;
                padding: 0px !important;
                padding-right: 2px !important;
                font-size: 14px
              }
              *{font-family: 'Arial', sans-serif !important;

}
              th{
                white-space: nowrap;
                text-align: center;
              }
              .col-4, .col-6{
              padding: 2px !important;}
            </style>
          </head>
          <body>
            <div class='container-fluid'>
              <div class='row'>
              <div class='col-12 header'>
                <div class='row'>
                <div class='col-4 text-center'>
                المملكة العربية السعودية<br>
                وزارة التعليم
                <br>
                ${g.user.name}
              </div>
              <div class='col-4 text-center'>
                <img src='https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg' style='width: 150px'>
              </div>
              <div class='col-4'>

                اسم النموذج: سجل العمل الرسمي
                <br>
                سجل دوام الموظفين للعام الدراسي: ${date2}
                <br>
                يوم: ${dayname} الموافق ${date}
              </div>
              </div>
              </div>
              <div class='col-6'>
                <table class='table table-striped table-sm'>
                  <thead style='background: #C0EBA6'>
                  <tr><th colspan="6" class='text-center' style='background: #A2CA71'>سجل الحاضرين قبل الاصطفاف الصباحي</th></tr>
                    <tr><th>
                      م
                    </th>
                    <th>
                      الاسم
                    </th>
                    <th>
                      الوظيفة
                    </th>
                    <th>
                      وقت الحضور
                    </th>
                    <th>
                      وقت الخروج
                    </th></tr>
                  </thead>
                  <tbody>${attend}</tbody>
                </table>
                
              </div>
              <div class='col-6'>

                <table class='table table-striped table-sm'>
                  <thead style='background: #C0EBA6'>
                  <tr><th colspan="6" class='text-center' style='background: #A2CA71'>المعلمون المتأخرون</th></tr>
                    <tr><th>
                      م
                    </th>
                    <th>
                      الاسم
                    </th>
                    <th>
                      الوظيفة
                    </th>
                    <th>
                      وقت الحضور
                    </th>
                    <th>
                      وقت الخروج
                    </th></tr>
                  </thead>
                  <tbody>${late}</tbody>
                </table>
                <table class='table table-striped table-sm'>
                  <thead style='background: #C0EBA6'>
                    <tr><th colspan="6" class='text-center' style='background: #A2CA71'>الموظفون الغائبون</th></tr>
                    <tr><th>
                      م
                    </th>
                    <th>
                      الاسم
                    </th>
                    <th>
                      الوظيفة
                    </th>
                    <th>
                      سبب الغياب
                    </th></tr>
                  </thead>
                  <tbody>${ghiab}</tbody>
                </table>
                <table class='table table-striped table-sm'>
                  <thead style='background: #C0EBA6'>
                    <tr><th colspan="6" class='text-center' style='background: #A2CA71'>المعلم المناوب بداية ونهاية العمل</th></tr>
                    <tr><th>
                      اسم المعلم
                    </th><th>التوقيع</th></tr>
                  </thead>
                  <tbody>${shifts == "" ? `<tr>
                  <td>&nbsp;&nbsp;&nbsp;</td>
                  <td>&nbsp;&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;&nbsp;&nbsp;</td>
                  <td>&nbsp;&nbsp;</td>
                </tr>` : shifts}</tbody>
                </table>
                <table class='table table-striped table-sm'>
                  <thead style='background: #C0EBA6'>
                    <tr><th colspan="6" class='text-center' style='background: #A2CA71'>المشرف الزائر</th></tr>
                    <tr><th>
                      اسم المشرف
                    </th>
                    <th>
                      تخصصه
                    </th>
                    <th>
                      توقيعه
                    </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <span style='position: fixed; bottom: 0px;'>ملاحظة: يغلق الدوام مرتين في الفترة الأولى بعد بداية البرنامج الصباحي مباشرة وفي المرة الثانية عند بداية الحصة الأولى مباشرة</span>
            </div>
            </div>
            ${two}
          </body>
        </html>
        `)
        win.document.close();
        setTimeout(function(){
          win.document.designMode = "on"
          win.print();
        }, 1000)
      },
      getReport(){
        var g = this;
        axios
          .post(api + "/user/teachers/reports/daily", {
            jwt: this.user.jwt,
            date: g.date,
            with_4: true
          })
          .then(function (r) {
            if (r.data.status == 100) {
              var gx = {};
              r.data.response.forEach(function (a) {
                if(!gx[a.status?.toString()]){
                  gx[a.status?.toString()] = 0;
                }
                gx[a.status?.toString()]++
              });
              g.reports = gx;
              g.list = r.data.response
              g.leaves = r.data.leaves
              function compare( a, b ) {
                if ( a?.time < b?.time ){
                  return -1;
                }
                if ( a?.time > b?.time ){
                  return 1;
                }
                return 0;
              }
              g.list.sort( compare );
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ 2",
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
            g.loaded = true;
          })
          .catch(function () {
            g.loading = false;
            g.table = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    }
}
</script>

<style>

</style>
<style>
.canvasjs-chart-credit{
  display: none !important;
}
.favbtn:hover{
  background: #eee !important;
}
</style>